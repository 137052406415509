body {
  font-family: "SF Pro Display", sans-serif;
}

:root {
  --dark-border: solid 1px #2b3139;
  --dark-bg: #181a20;
  --dark-primary-clr: #eaecef;
  --dark-secondary-clr: #848e8c;
  --ff-primary-landing: "SF Pro Display", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

.form-container span {
  color: #dc362e;
  margin-top: 5px;
  text-align: start;
  font-size: 14px;
}

.landing_header {
  display: flex;
  height: 100%;
  flex-direction: column;
}

header.scrolled .MuiAppBar-colorPrimary {
  height: 74px;
  display: flex;
  flex-direction: row;
  transition: background-color 0.3s ease;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  margin: 0;
  background-color: transparent;
  background: 050505;
}

.head-btn {
  height: 40px;
  width: 100px;
  padding: 8px 24px;
  border-radius: 12px;
  background-color: #2f2d2f;
  color: #fff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 1.5s ease;
  border: none;
}

.submit_mainbtn {
  padding-bottom: 30px;
  margin: auto;
}

.submit_btn {
  padding: 8px 24px;
  border-radius: 21px;
  color: #ffffff;
  background-color: #0077ff;
  gap: 5px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

.arrow_icon {
  width: 4vw;
  height: 4vw;
  background: #161616;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;
  border: 2px solid #888888;
}

.arrow_icon span {
  color: #888888;
  font-size: 1vw;
}

.explore_section p {
  color: #cbcbcb;
  padding: 10px;
  font-size: 1vw;
}

.backgrd_imageabout {
  background-image: url("./assets/aboutus_img.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding-top: 60px;
}

.backgrd_imageaboutnew {
  background-image: url("./assets/contact_us.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding-top: 60px;
}

.getcard_sub_subhead {
  /* font-family: Helvetica; */
  font-size: 22px;
  line-height: 1.71;
  text-align: left;
  color: #bdbdbd;
}

.getting_card_content h1 {
  /* font-family: Helvetica; */
  font-size: 43px;
  text-align: left;
  color: #ffffff;
}

.getting_card_content p {
  /* font-family: Helvetica; */
  font-size: 16px;
  text-align: left;
  color: #cbcbcbcc;
  width: 87%;
  max-width: 500px;
}

.getting_card_contentPro h1 {
  /* font-family: Helvetica; */
  font-size: 43px;
  text-align: left;
  color: #ffffff;
}

.getting_card_contentNew p {
  /* font-family: Helvetica; */
  font-size: 16px;
  text-align: left;
  color: #cbcbcbcc;
  width: 87%;
  /* padding-top: 10px; */
}

.exchange_text p {
  width: 97%;
  margin: 0px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

/* .footer_section {
  padding: 40px 80px;
  padding: 0px 0px 10px 0px;
  position: relative;
} */

.footer_section {
  position: relative;
}

.mainicons_div {
  /* gap: 10px; */
  padding-top: 45px;
  /* padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer; */
}

/* .mainicons_div span {
  display: flex;
  width: 54px;
  height: 43px;
  background: #161616;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ffffff80;
  color: #ffffff80;
} */

.telegram_icons {
  width: 26px;
  height: 25px;
  background: #212121;
  border-radius: 3px;
  text-align: center;
}

.footer_links_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 14px;
}

.footer_links_span {
  /* font-family: "Helvetica", sans-serif; */
  font-size: 14px;
  text-align: left;
  color: #cbcbcbcc;
  cursor: default;
}

.footer_links_div a {
  text-decoration: none;
  text-decoration: none;
  text-align: justify;
  cursor: default;
}

.footer_links_div a:hover .footer_links_span {
  color: #0077ff;
}

.border_services {
  display: flex;
  width: 20px;
  height: 4px;
  background: #0077ff;
}

.footer_head {
  /* font-family: "Helvetica", sans-serif; */
  font-size: 19px;
  text-align: start;
  color: #ffffff;
}

.telegram_icons span svg {
  color: #bdbdbd;
}

.footer_second_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.about_second_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.getting_card_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.getting_card_contentPro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  /* position: fixed; */
}

/* .home_main {
  padding: 53px 90px 12px 30px;
} */

.hero_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.getting_card_content::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 78px;
  background: #0077ff;
  top: 13px;
  right: 100%;
}

.getting_card_contentPro::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 88px;
  background: #0077ff;
  /* top: 48px; */
  /* right: 0%; */
  left: -17px;
  top: 12px;
}

.about_second_main .happyclient_tag {
  width: 78%;
  margin-top: 10px;
  text-align: start;
  color: #cbcbcb;
  font-size: 15px;
}

.about_second_main h2 {
  text-align: start;
}

.aboutus_header {
  display: flex;
  flex-direction: column;
}

.aboutus_header .about_second_main {
  margin-top: auto;
}

.form-container .input_field {
  font-size: 16px;
  color: #ffffff33;
  text-align: start;
}

/* .exchange_text {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 4px;
  cursor: pointer;
} */

.exchange_text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 4px; */
  cursor: pointer;
  /* border: 1px solid grey; */
  margin-bottom: 24px;
}

.exchange_text:hover > p {
  color: #fff;
}

.exchange_text:hover span i {
  color: #fff;
}

.linkedin_tag {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
}

.instagram_tag {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4px;
}

.link_maintag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 121px;
  /* right: 166px; */
  right: 134px;
  cursor: pointer;
}

.link_maintag_menu {
  position: absolute;
  bottom: 121px;
  right: 49px;
  cursor: pointer;
}

.linkedin_tag p {
  /* font-family: Helvetica; */
  font-size: 16px;
  text-align: left;
  color: #cbcbcbcc;
  width: 87%;
  margin: 0;
}

.instagram_tag p {
  /* font-family: Helvetica; */
  font-size: 16px;
  text-align: left;
  color: #cbcbcbcc;
  width: 87%;
  margin: 0;
}

.instagram_tag,
.linkedin_tag span {
  color: #ffffff;
}

.contact_formmain {
  width: 64%;
  height: max-content;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.styled-input {
  border: none;
  outline: none;
  padding: 17px 5px;
  transition: border-color 0.3s ease;
  background-color: #11101129;
  border-bottom: solid 1px #ffffff33 !important;
  font-size: 14px;

  color: #fafafa;
}

.styled-input::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.styled-input:focus {
  border-bottom: 2px solid #0077ff;
}

.styled-input::placeholder {
  color: #ffffff33;
}

.rightside_menu a:hover {
  color: transparent;
  text-decoration: none;
}

.menu_img {
  cursor: pointer;
}

.rightside_menu {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 18px;
}

.scroll_nav_main_top {
  height: 100vh;
  /* display: flex; */
}

.scroll_nav_main {
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  margin: auto;
  height: 85%;
}

.nav_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  /* width: 19%; */
  right: 0;
  left: initial;
  top: 0;
  bottom: 0;
  background: transparent !important;
}

.nav_mainopen {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  left: initial;
  top: 0;
  bottom: 0;
  background: #1a1a1b !important;
  padding-top: 21px;
  align-items: end;
  padding-left: 30px;
  /* border: 1px solid grey; */
  /* box-shadow: 0px 3px 3px -1px #1a1a1b; */
}
.navbar_closeicon > i {
  font-size: 30px;
}

.nav_main ul {
  flex-direction: column !important;
  height: 100%;
  justify-content: center;
  position: relative;
  width: max-content;
}

.nav_main_ul {
  flex-direction: column !important;
  height: 100%;
  justify-content: start;
  position: relative;
  padding-top: 30px;
  align-items: end;
}

.nav_main ul::after {
  content: "";
  position: absolute;
  right: 13px;
  top: 50%;
  width: 3px;
  height: 56%;
  background: #ffffff26;
  z-index: 5;
  transform: translate(0, -50%);
}

.nav_main_ul::after {
  content: "";
  position: absolute;
  right: 13px;
  top: 50%;
  width: 3px;
  height: 56%;
  background: transparent;
  z-index: 5;
  transform: translate(0, -50%);
}

.nav_main ul li {
  position: relative;
  margin: 15px 0px;
  padding: 0.5rem 1rem;
  padding-right: 40px;
}

.nav_main_ul li {
  position: relative;
  padding: 0.5rem 1rem;
  padding-right: 55px;
}

.link_maintag_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: absolute;
  bottom: 20px;
  right: 49px;
  cursor: pointer;
}

.navbar_closeicon {
  /* position: absolute; */
  color: white;
  /* top: 17px;
    right: 17px; */
  /* padding-top: 15px; */
  font-size: 25px;
}

.hidenCloseicon {
  display: none;
}

.nav_main ul li::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #343434;
  right: 0;
  top: 11px;
  z-index: 10;
  cursor: pointer;
}

.nav_main_ul li::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #343434;
  right: 0;
  top: 14px;
  z-index: 10;
}

.nav_main ul li.section1Active a {
  color: white !important;
}

.nav_main_ul li.section1Active a {
  color: white !important;
}

.nav_main ul li.section1Active::after {
  background: #171715;
  z-index: 10;
  border: 1px solid #ffffff;
}

.nav_main_ul li.section1Active::after {
  background: #171715;
  z-index: 10;
  border: 1px solid #ffffff;
}

.nav_main ul li.section1Active::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgb(255 255 255);
  right: 8px;
  top: 18px;
  z-index: 15;
}
.menuopenzindex {
  z-index: 10 !important;
}
.menuclosezindex {
  z-index: 10000 !important;
}

.nav_main_ul li.section1Active::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(255 255 255);
  right: 8px;
  top: 21px;
  /* transform: translate(0%, -50%); */
  z-index: 15;
}

.all_Section_main {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.all_Section_main::-webkit-scrollbar {
  display: none;
}

.section_main {
  height: 100%;
}

.product_menu {
  width: 100%;
}

.product_menu > div {
  width: 100%;
  position: relative;
}

.product_menu div span {
  position: absolute;
  right: 10px;
  top: 2px;
  display: none;
}

.product_menu > div:hover span {
  display: inline-block;
}

.product_row_Scroll {
  /* overflow: auto; */
  /* height: 100%; */
  /* padding-right: 0; */
  overflow-y: scroll;
  padding-top: 180px;
}

.product_row_Scroll > .row::-webkit-scrollbar,
.product_row_Scroll::-webkit-scrollbar {
  display: none;
}

.card_product {
  background: #ffffff26;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  gap: 19px;
  margin-bottom: 30px;
}

.img_div {
  width: 100%;
}

.img_div img {
  width: 100%;
  border-radius: 8px;
}

.card_product p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.rightside_menu .menu_img {
  display: none;
}

.nav_mainmenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  left: initial;
  top: 0;
  padding-top: 63px;
  padding-right: 0;
  background: #1a1a1b !important;
  width: 63%;
}

.containerfluid_menu {
  height: 100%;
  width: 81%;
}

.nav_mainmenu_ul {
  flex-direction: column !important;
  height: 100%;
  justify-content: start;
  position: relative;
}

.nav_mainul_limenu {
  margin: 0;
}

.nav_mainmenu_ul::after {
  background-color: transparent;
}

/* .product_row_Scroll {
  padding-bottom: 120px;
} */

.active_class {
  display: block;
}

.nonactive_class {
  display: none;
}

/* .after_classecir {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #343434;
  right: 0;
  top: 11px;
  z-index: 10;
} */

/* .before_classecir {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 8px;
  top: 18px;
  z-index: 15;
} */

/* Modal */

.service-modal-content {
  border-radius: 10px;
}

.whole_popupmodal {
  padding-top: 80px;
}

.landing__contact--form {
  width: 522px;
  padding: 28px 28px 24px;
  border-radius: 9px;
  /* background-image: url("../src//assets//aboutus_img.webp");
  background-repeat: no-repeat;
  background-size: cover; */
}

.primary-landing-main-btn:hover .right-arr {
  transform: translateX(100px);
}

.primary-landing-main-btn {
  outline: none;
  padding: 12px 24px;
  border-radius: 48px;
  background-color: #0e0e10;
  font-family: var(--ff-primary-landing) !important;
  font-size: var(--fz-16-landing);
  font-weight: 500;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 1px solid white;
}

.primary-landing-main-btn::before {
  position: absolute;
  content: "";
  background-color: var(--clr-secondary-landing);
  left: -100%;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: inherit;
  transition: left 0.7s;
  z-index: -1;
}

.primary-landing-main-btn:hover::before {
  left: 0%;
  opacity: 1;
}

.text-danger {
  font-size: 12px;
  color: red;
  margin-top: 9px !important;
  text-align: start;
}

.landing__contact--form-title {
  font-family: var(--ff-primary-landing) !important;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.landing__contact--form label {
  font-family: var(--ff-primary-landing) !important;
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
}

.landing__contact--form::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.landing__contact--form .landing__contact-medium-input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-family: var(--ff-primary-landing) !important;
  font-size: 14px;
  color: #fff;
}

.landing__contact--form .landing__form-input {
  border: none;
  border-bottom: 1px solid #ffffff33 !important;
  outline: none;
  padding: 17px 5px;
  font-size: 16px;
  color: #ffffff;
  transition: border-color 0.3s ease;
  background-color: transparent;
  width: 100%;
  resize: none;
}

.landing__contact--form .landing__form-input::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.whole_popupmodal .modal-content {
  background-color: transparent !important;
}

/*  landing-form */
.margin-bottom-land {
  margin-bottom: 0px !important;
}

.career-btn:hover .right-arr {
  transform: translateX(100px);
}

.career-file-btn:hover .right-arr {
  transform: translateX(100px);
}

.right-arr {
  display: inline-block;
  transition: transform 1s;
}

.primary-landing-hero-btn:hover .right-arr {
  transform: translateX(100px);
}

.primary-landing-main-btn:hover .right-arr {
  transform: translateX(100px);
}

.explore__card-btn:hover .right-arr {
  transform: translateX(100px);
  transition: transform 1s;
}

.landing__contact--form .landing__form-input:active,
.landing__contact--form .landing__form-input:focus {
  /* border-bottom: solid 1.5px var(--clr-secondary-landing); */
}

textarea.landing-textarea {
  min-height: 100px !important;
  max-height: 100px !important;
}

.landing__contact--form .form-phone {
  padding: 3px 0;
  border-bottom: solid 1.5px rgba(255, 255, 255, 0.1);
  outline: none;
  background: transparent !important;
  width: 100%;
  font-family: var(--ff-primary-landing) !important;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin-bottom: 32px !important; */
}

.landing__contact--form .form-phone:active,
.landing__contact--form .form-phone:focus {
  /* border-bottom: solid 1.5px var(--clr-secondary-landing) !important; */
}

.landing__contact--form .form-contact-medium {
  padding: 12px 0;
  border-bottom: solid 1.5px rgba(255, 255, 255, 0.1);
  outline: none;
  margin-bottom: 10px;
  background: transparent !important;
  width: 100%;
  font-family: var(--ff-primary-landing) !important;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin-bottom: 32px !important; */
  display: flex;
}

.landing__contact--form .form-contact-medium:active,
.landing__contact--form .form-contact-medium:focus {
  /* border-bottom: solid 1.5px var(--clr-secondary-landing) !important; */
}

.landing__contact--form .form-phone .form-control {
  border: none !important;
  color: #fff;
  padding-left: 47px;
  background: transparent;
}
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}

.landing__contact--form.hero-modal-contact {
  width: 100% !important;
  background-color: #05050575 !important;
  position: relative;
  border: 1px solid #7f7f7f29;
  backdrop-filter: blur(30px);
}

.hero-modal-contact .form-phone .form-control {
  color: #ffffff;
}
.react-tel-input .country-list {
  background-color: #151515 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #414141 !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #999292 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}
.hero-modal-contact .form-phone .form-control:focus {
  border: none !important;
  box-shadow: none !important;
}

.contact-us-title {
  color: #ffffff !important;
}

.service-modal-btn {
  /* position: absolute; */
  right: 8px;
  background: transparent;
  width: 40px;
  height: 40px;
  font-size: 34px;
  font-weight: 400;
  color: #000;
  border-radius: 0;
  top: 8px;
  opacity: 1;
  color: #ffffff;
  border: none;
}

.contact-us-label {
  color: rgba(14, 14, 16, 0.8) !important;
}

.landing__contact--form .landing__contact-medium-input,
.landing__contact--form .landing__form-input,
.landing__contact--form .form-phone,
.landing__contact--form .form-contact-medium {
  font-size: 14px;
}

.contact-us-input {
  border-bottom: solid 1px #ffffff33 !important;
  color: #fafafa !important;
}

.contact-us-input .dropdown-toggle::after {
  color: #fafafa;
}

.contact-us-input i {
  color: #fafafa;
}

.contact-us-input .landing__form-drop-menu {
  background-color: #161415 !important;
  border: 1px solid #fafafa !important;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  border: none;
  z-index: 1;
}

.contact-us-input .landing__form-drop-menu .dropdown-item:focus,
.contact-us-input .dropdown-item:hover {
  color: #ffffff;
  background-color: #5f5d5d;
}

.primary-contact-us-form .form-phone .form-control {
  color: #0e0e10;
}

.career-form-phone .form-control {
  border: none !important;
}

/* .contact-us-input .landing__contact-medium-input {
  color: #fafafa;
} */

.contact-us-input .landing__contact-medium-input {
  background: transparent;
  border: none !important;
  outline: none !important;
  font-size: 14px;
  color: #fafafa;
}

.contact-us-input .landing__contact-medium-input::placeholder {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.2);
}

.react-tel-input .country-list .country-name {
  color: #fff !important;
}

.react-tel-input .country-list {
  text-align: left !important;
}

.contact-medium-width {
  width: 85% !important;
}

.contact-us-input .landing__contact-medium-input:focus {
  border: none !important;
  outline: none !important;
}

.contact-us-phone .country-list {
  background-color: #fff;
}

.contact-us-phone .country-list .country:hover {
  background-color: #979da717;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border-radius: 5px !important;
}
.contact-us-phone .country-list .country.highlight {
  background-color: #979da717;
  color: #000;
}

.contact-us-phone .country-list .country .dial-code {
  color: inherit;
}

.landing__form-dropdown {
  font-size: 16px;
  transition: all 0.4s;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  margin-right: 9px;
}

.contact-us-input .dropdown-toggle::after {
  color: #fafafa;
}

.headerLinks .dropdown-toggle::after {
  display: none;
}

.headerLinks .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.headerLinks .nav-item .dropdown-menu {
  display: none;
  opacity: 0;
}

.landing__form-drop-menu {
  position: absolute;
  /* z-index: 1000; */
  z-index: 0;
  display: none;
  min-width: 0rem !important;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #1a1a1a;
  background-clip: padding-box;
  border: 1px solid rgb(49 49 49);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.landing__form-drop-menu .dropdown-item {
  display: block;
  padding: 10px 20px;
  clear: both;
  font-weight: 500;
  line-height: 1.428571429;
  color: #fff5f5 !important;
  white-space: normal;
  font-size: 14px;
}

.landing__form-drop-menu .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  background-color: #222121;
}

.landing__form-drop-menu .dropdown-item {
  display: block;
  padding: 10px 20px;
  clear: both;
  font-weight: 500;
  line-height: 1.428571429;
  color: #fff5f5 !important;
  white-space: normal;
  font-size: 14px;
}

.landing__form-drop-menu .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  background-color: #222121;
}

.contact-us-input i:hover {
  color: #222121;
}

/* Gif */

.normal_imageant:hover {
  content: url("../src/assets/AntiIsraelWar.gif");
}

.normal_imagesckn:hover {
  content: url("../src/assets/sckngif.gif");
}

.normal_imagerexi:hover {
  content: url("../src/assets/rexitngif.gif");
}

.normal_imagetttn:hover {
  content: url("../src/assets/tttngif.gif");
}

.normal_imagethubbet:hover {
  content: url("../src/assets/HupBetTNgif.gif");
}

.normal_imageticgame:hover {
  content: url("../src/assets/IC.gamegif.gif");
}

.normal_imageticmlm:hover {
  content: url("../src/assets/WXDLgif.gif");
}

.normal_imageticcmbot:hover {
  content: url("../src/assets/WyScalegif.gif");
}

.normal_imageticmvbot:hover {
  content: url("../src/assets/MevBotTngif.gif");
}

.normal_imageticicoone:hover {
  content: url("../src/assets/BabyWalrusgif.gif");
}

.normal_imageticicotwo:hover {
  content: url("../src/assets/ZurumiICOgif.gif");
}

.normal_imageticicothree:hover {
  content: url("../src/assets/VOltrixICOgif.gif");
}

.normal_imageticicryex:hover {
  content: url("../src/assets/FidexExchangegif.gif");
}

.normal_imageticicryone:hover {
  content: url("../src/assets/VOltrixExchangegif.gif");
}

.normal_imageticicrytwo:hover {
  content: url("../src/assets/VoltrixAppgif.gif");
}

.normal_imageticiapp:hover {
  content: url("../src/assets/Teleminigamegif.gif");
}

.normal_imageticiappone:hover {
  content: url("../src/assets/Bitgetappgif.gif");
}

.normal_imageticiapptwo:hover {
  content: url("../src/assets/TrustPeappgif.gif");
}

.normal_imageticiappthre:hover {
  content: url("../src/assets/FidexAppgif.gif");
}

.normal_imagemonk:hover {
  content: url("../src/assets/Mitcome.gif");
}

.normal_imageticicrybit:hover {
  content: url("../src/assets/BitBuyerxgif.gif");
}

.normal_imageticbotone:hover {
  content: url("../src/assets/Enzogif.gif");
}

.normal_imageticbottwo:hover {
  content: url("../src/assets/DaulahBot.gif");
}

.normal_imageticbotthre:hover {
  content: url("../src/assets/flashGif.gif");
}

.normal_imageticbotfour:hover {
  content: url("../src/assets/marketMakingBotgif.gif");
}

.normal_imageticiblockcha:hover {
  content: url("../src/assets/ownblockchainGif.gif");
}

.normal_imageticidefione:hover {
  content: url("../src/assets/ownblockchainGif.gif");
}

.normal_imageticidefione:hover {
  content: url("../src/assets/keedexgif.gif");
}

.normal_imageticidefitwo:hover {
  content: url("../src/assets/yarloogif.gif");
}

.normal_imageticinft:hover {
  content: url("../src/assets/nftgif.gif");
}

.normal_imageticinftone:hover {
  content: url("../src/assets/nftgif2.gif");
}

.normal_imageticinfttwo:hover {
  content: url("../src/assets/nftgif3.gif");
}

.normal_imageticinftthree:hover {
  content: url("../src/assets/loudgit.gif");
}

.normal_imageticinftpay:hover {
  content: url("../src/assets/paymentgif.gif");
}

.react-tel-input .flag-dropdown {
  border: none !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: transparent !important;
  border: none !important;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 300px;
  outline: none !important;

  color: #fafafa;
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.contact-med-flex {
  display: flex !important;
}

.form-pl-0 {
  padding-left: 10px !important;
}

/* Media Query */

@media (min-width: 981px) and (max-width: 1199px) {
  /* .nav_main {
    width: 29%;
  } */

  .center-box {
    overflow: auto;
  }

  .center-box::-webkit-scrollbar {
    display: none;
  }

  .main_header_change .container-fluid {
    position: fixed;
    top: 0px;
  }

  .link_maintag {
    position: absolute;
    bottom: 85px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* .nav_main {
    width: 24%;
  } */

  .center-box {
    overflow: auto;
  }

  .center-box::-webkit-scrollbar {
    display: none;
  }

  .link_maintag {
    position: absolute;
    bottom: 85px;
  }
}

@media (min-width: 768px) and (max-width: 982px) {
  .backgrd_imageabout {
    overflow: auto;
  }

  .link_maintag {
    position: absolute;
    bottom: 85px;
  }

  .backgrd_imageabout::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 1100px) {
  .rightside_menu .menu_img {
    display: inline-block;
  }
  .nav_main_ul li {
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .footer_section {
    padding-bottom: 30px;
  }

  .nav_main_ul li {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .header.scrolled .MuiAppBar-colorPrimary {
    padding: 0;
  }

  .scroll_nav_main_top {
    height: max-content;
  }

  .contact_formmain {
    width: 100%;
  }

  .nav_main {
    display: none;
  }

  .nav_mainopen {
    position: fixed;
    top: 0;
  }

  .mainicons_div {
    padding-top: 0;
  }

  .product_row_Scroll {
    margin-top: 34px;
  }

  .contact_formmain {
    margin-bottom: 70px;
  }

  .row {
    margin-right: 0;
  }

  .aboutus_header {
    padding: 0;
  }

  .about_second_main h2 {
    font-size: 6vw;
  }

  .about_second_main > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .nav_nextdiv {
    height: 100% !important;
    justify-content: right !important;
    padding-right: 31px;
    display: inline-block !important;
  }

  .navbar_closeicon {
    padding-left: 88%;
  }

  /* .main_header_change {
    background-color: #191919 !important;
  } */

  .product_menu div span {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  /* .nav_main_ul li {
    width: max-content;
  } */

  .getting_card_content {
    padding-bottom: 24px !important;
  }

  .responsive-body {
    height: 100vh;
    overflow: scroll;
  }

  .responsive-body::-webkit-scrollbar {
    display: none;
  }

  .backgrd_imageabout {
    height: max-content !important;
  }
}

@media (max-width: 480px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
  .home-main-col {
    padding-right: 0px;
    padding-left: 12px;
  }
  .form-container {
    padding: 24px 12px;
  }

  .scroll_nav_main {
    width: 100%;
  }
  .link_maintag_menu {
    flex-direction: column;
  }
  /* .nav_mainopen {
    width: 30% !important;
  } */
  .getting_card_content::before {
    left: -2px;
  }
}

@media (max-width: 425px) {
  .getcard_sub_subhead {
    font-size: 5vw;
  }

  .getting_card_content h1 {
    font-size: 9vw;
  }

  .getting_card_contentPro h1 {
    font-size: 9vw;
  }

  .getting_card_content p {
    font-size: 4vw;
  }

  /* .mainicons_div span {
    width: 12vw;
    height: 10vw;
  } */

  .footer_head {
    font-size: 5vw;
  }

  .footer_links_span {
    font-size: 4vw;
  }

  .about_second_main .happyclient_tag {
    font-size: 4vw;
    text-align: left;
  }

  .exchange_text p {
    font-size: 4vw;
  }

  .getting_card_contentNew p {
    font-size: 5vw;
  }

  /* .nav_mainopen {
    width: 62%;
  } */
}

@media (min-width: 600px) and (max-width: 768px) {
  .subspan_two {
    position: absolute;
    left: 14%;
  }

  .arrow_icon {
    width: 5vw;
    height: 5vw;
  }

  .arrow_icon span {
    font-size: 3vw;
  }
}

@media (min-width: 425px) and (max-width: 599px) {
  .subspan_two {
    position: absolute;
    left: 3%;
  }

  .arrow_icon {
    width: 9vw;
    height: 9vw;
  }

  .arrow_icon span {
    font-size: 4vw;
  }

  .getting_card_contentPro::before {
    position: absolute;
    right: 92%;
  }
}

@media (min-width: 320px) and (max-width: 424px) {
  .subspan_two {
    position: absolute;
    left: 4%;
  }

  .arrow_icon {
    width: 9vw;
    height: 9vw;
  }

  .arrow_icon span {
    font-size: 4vw;
  }

  .getting_card_contentPro::before {
    position: absolute;
    right: 92%;
  }
}
