.header-wrapper {
  width: 95%;
  margin: 0 auto;
  max-width: 1600px;
}

.main-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.slide-header {
  height: 100vh;
  animation: shrinkHeader 1s ease forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  background-color: #050505;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes shrinkHeader {
  100% {
    height: 74px;
    background-color: transparent;
  }
}

.main-bg {
  /* border: 1px solid grey; */
  min-height: 100vh;
  background: #131213;
  /* padding-top: 10vh; */
  background-repeat: no-repeat;
  background-size: cover;
  /* overflow-y: auto; */
}

.main-bg.about-bg {
  background-image: url("../assets/aboutus_img_copy.webp");
}

.main-bg.service-bg {
  background-image: url("../assets/service-bg.webp");
}
.main-bg.projects-bg {
  background-image: url("../assets/projects-bg.webp");
  height: 100vh;
  overflow-y: scroll;
}
.main-bg.contact-bg {
  background-image: url("../assets/contact-bg.webp");
}

.section-wrapper {
  margin-top: 80px;
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.logo-wrapper {
  width: 84px;
}

.logo-wrapper img {
  width: 100%;
  object-fit: cover;
}

.logo-wrapper.logo-slider {
  width: 84px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: imageSlide 2s ease-in-out forwards;
  animation-iteration-count: 1;
  z-index: 1000;
  padding-top: 20px;
  animation-delay: 1.2s;
  /* transform: scale(1.2); */
}

@keyframes imageSlide {
  100% {
    top: 0%;
    left: 8.5%;
    /* transform: translate(0, 0) scale(1); */
    transform: translate(0, 0);
  }
}

.content-wrapper {
  margin: 0 auto;
  width: 88%;
}

.header-btn {
  padding: 8px 12px;
  border-radius: 90px;
  border: solid 1px #2d292d;
  outline: none;
  background: transparent;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #cbcbcb;
  display: flex;
  align-items: center;
  gap: 6px;
}

.header-btn.landing-header-btn {
  opacity: 0;
  animation: btnVisible 2s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
}

@keyframes btnVisible {
  100% {
    opacity: 1;
  }
}

.clients-row {
  gap: 40px;
  display: flex;
}

.about-get-in-touch {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
}

.about-get-in-touch span {
  padding: 10px;
  border-radius: 60px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.about-get-in-touch span:hover {
  border: solid 1px #07f;
}

.about-get-in-touch span:hover > svg {
  color: #07f;
}

.about-get-in-touch p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.18px;
  color: rgba(203, 203, 203, 0.8);
  margin: 0px;
}

.about-get-in-touch span svg {
  color: rgba(255, 255, 255, 0.5);
}

.getcard_sub_subhead {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}

.getting_card_content h1 {
  font-size: 62px;
  font-weight: 500;
  color: #fff;
}

.getting_card_content p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.63;
  letter-spacing: 0.16px;
  color: rgba(203, 203, 203, 0.8);
}

.get-in-touch-row {
  margin-top: 60px;
}

.clients-num {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}

.happyclient_tag {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  letter-spacing: 0.16px;
  color: rgba(203, 203, 203, 0.8);
}

.border_services {
  margin: 10px 0px;
}

.nav_main ul::after {
  content: "";
  position: absolute;
  right: 13px;
  /* top: 100%; */
  width: 3px;
  height: 100%;
  background: #ffffff26;
  z-index: 5;
  /* transform: translate(0, -50%); */
}

.link_maintag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 2%;
  right: 50px;
  /* right: 134px; */
  cursor: pointer;
}

.nav_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 0;
  left: initial;
  top: 0;
  bottom: 0;
  background: transparent !important;
  height: 88%;
  margin: auto;
  align-items: end;
}

.hamburger-menu i {
  font-size: 20px;
}

.linkedin_tag p,
.instagram_tag p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  color: rgba(255, 255, 255, 0.5);
  width: 87%;
  margin: 0;
}

.link_maintag {
  gap: 20px;
}

.navbar_closeicon {
  display: flex;
  justify-content: end;
}

.nav_main ul li::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #343434;
  right: 5px;
  top: 17px;
  z-index: 10;
  cursor: pointer;
}

.nav_main ul li.section1Active::after {
  background: #171715;
  z-index: 10;
  border: 1px solid #ffffff;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 0px;
}

.nav_main ul li.section1Active::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgb(255 255 255);
  right: 6px;
  top: 16px;
  z-index: 15;
}

.nav_link-clr {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.14px;
  color: rgba(255, 255, 255, 0.5);
}

.nav_main ul li.section1Active .nav_link-clr {
  font-size: 18px;
}

/* landing page styles */

.service_rangeheader h1 {
  font-size: 74px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  width: 100%;
  margin: auto;
}

.service_rangeheader p {
  padding: 32px;
  /* width: 36%; */
  line-height: 24px;
  margin: auto;

  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: #cbcbcb;
}

.landing-bg {
  background-color: rgba(17, 17, 18, 0.94);
  height: 100vh;
  background-image: url("../assets/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: scroll;
}

.landing-title {
  font-size: 74px;
  font-weight: 500;
  line-height: 1.14;
  text-align: center;
  color: #fff;
  width: 84%;
  margin: auto;
  max-width: 800px;
  /* border: 1px solid grey; */
}

.landing-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: #cbcbcb;
  /* border: 1px solid; */
  width: 85%;
  margin: 20px auto;
  max-width: 550px;
  position: relative;
}

.landing-content::before {
  position: absolute;
  content: "";
  background-color: #07f;
  width: 6px;
  height: 10px;
  left: -15px;
  height: 100%;
}

.landing-main {
  margin-top: 106px;
}

/* .explore-btn-wrap {
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
} */

.explore-btn-wrap {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.landing-explore-btn {
  width: 80px;
  height: 80px;
  border: solid 1.5px #8f8f8f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.landing-explore-btn i {
  font-size: 20px;
  color: #8f8f8f;
}

.landing-explore-btn:hover {
  border: solid 1.5px #07f;
}

.landing-explore-btn:hover i {
  color: #07f;
}

.explore-btn-content {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.16px;
  text-align: center;
  color: #cbcbcb;
  margin-top: 14px;
  cursor: pointer;
}

.projects-sidebar {
  position: fixed;
}

.react-tel-input .form-phone .form-control {
  border: none !important;
  outline: none !important;
}

.getting_card_contentNew {
  position: fixed;
  overflow: scroll;
  height: 100vh;
  padding-bottom: 300px;
}

.contact-textarea {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #fafafa;
  border-bottom: solid 1px #ffffff33 !important;
}

.contact-textarea::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1600px) {
  @keyframes imageSlide {
    100% {
      top: 0%;
      left: 2.5%;
      transform: translate(0, 0) scale(1);
    }
  }
  .explore-btn-wrap {
    height: 40vh;
  }
}

@media (max-width: 1100px) {
  .nav_main {
    display: none;
  }
  .link_maintag {
    /* flex-direction: column; */
    right: 0px;
  }
  .clients-row {
    justify-content: space-evenly;
  }
  .link_maintag {
    bottom: 10%;
  }
  .nav_link-clr {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .landing-title {
    font-size: 62px;
  }
  .form-pl-0 {
    padding-left: 30px !important;
  }
  .get-in-touch-row {
    gap: 30px;
    flex-direction: column-reverse;
  }

  .getting_card_contentPro::before {
    left: -17px;
  }
  .main-bg.about-bg,
  .main-bg.service-bg,
  .main-bg.projects-bg,
  .main-bg.contact-bg {
    overflow: clip;
  }
  .section-wrapper {
    margin-top: 0px;
  }
  .section-one,
  .section-two,
  .section-three,
  .section-four {
    height: 100vh;
    overflow-y: scroll;
  }
  .project-card-row .project-card-col {
    padding-right: 0px;
  }
  .card_product-mb {
    margin-bottom: 100px;
  }
  .get-in-touch-row {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  /* .main-bg {
    height: auto;
  }
  .main-bg.about-bg,
  .main-bg.contact-bg {
    height: 100vh;
  } */
  .getting_card_contentNew {
    position: static;
    height: auto;
    padding-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .projects-sidebar {
    position: static;
  }
}

@media (max-width: 480px) {
  .landing-title {
    font-size: 50px;
    width: 100%;
  }
  .landing-content {
    font-size: 16px;
  }
  .explore-btn-wrap {
    height: 30vh;
  }
  .header-btn {
    padding: 6px 10px;
    font-size: 16px;
  }
  .getting_card_content p {
    width: 100%;
    font-size: 15px;
  }
  .getting_card_content h1 {
    font-size: 46px;
  }
  .getcard_sub_subhead {
    font-size: 18px;
  }
  .clients-row {
    gap: 0px;
  }
  .clients-num {
    font-size: 28px;
  }
  .happyclient_tag {
    font-size: 14px;
  }
  /* .section-wrapper {
    margin-top: 10px;
  } */
  .form-pl-0 {
    padding-left: 12px !important;
  }
  .contact_formmain {
    padding: 5px;
  }
  .main-bg.contact-bg {
    background-position: center;
  }
  /* .img_div img {
    object-fit: none !important;
  } */
  .get-in-touch-row {
    margin-top: 10px;
  }
  .contact-pr-0 {
    padding-right: 0px;
  }
}
